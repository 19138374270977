import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Marcus Dantas', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Hello, I'm ",
  name: 'Marcus',
  subtitle: 'I like making stuff on the web',
  cta: 'Find out more',
};

// ABOUT DATA
export const aboutData = {
  img: '1D2A2640_edited_BW.jpg',
  paragraphOne:
    'A Full Stack developer based in London. I have taken Le Wagon’s software engineering bootcamp to expand my programming skills. I love making software, working on new challenges and expanding my knowledge.',
  paragraphTwo:
    'I come from a background in Biomedical Sciences, during my studies I had the opportunity to work with programming for data processing and fell in love with the development process. Since then I’ve dove into programming teaching myself all I could and decided to undertake Le Wagon’s bootcamp to deepen my knowledge.',
  paragraphThree:
    'I am currently looking for new opportunities and challenges where I can contribute to a team in a fast-paced environment. ',
  resume: '../../Marcus_Dantas_Resume.pdf',
  // resume: 'https://drive.google.com/file/d/1O3IZH7PeLLBJtRKigdDZhkJQoaQ7NVqY/view', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'Adrastos.jpg',
    title: 'Adrastos',
    info:
      'Initiated and led the development of a social media platform that is architected around the connection of people through videogames.',
    info2:
      'Small MVP project where I had the opportunity to heavily contribute to the front end and part of the back end, Developed using Ruby on Rails 6.',
    url: 'https://adrastos-app.herokuapp.com/',
    repo: 'https://github.com/kinho107/adrastos', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Gamehub.jpg',
    title: 'Gamehub',
    info:
      'A Marketplace for videogames that was rapidly developed by myself and a small team in just a few days',
    info2: '',
    url: 'https://gamehub-kinho107.herokuapp.com/',
    repo: 'https://github.com/kinho107/Gamehub', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Mrs_cocktail.jpg',
    title: 'Mrs Cocktail',
    info:
      'A short project that showcases cocktails and allows the user the edit and create their own.',
    info2: '',
    url: 'https://mister-cocktail-marcus.herokuapp.com/',
    repo: 'https://github.com/kinho107/rails-mister-cocktail', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Questions? Get in touch!',
  btn: '',
  email: 'marcus@dantas.co.uk',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/marcus-dantas-dev/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/kinho107',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
